exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-get-started-jsx": () => import("./../../../src/pages/get-started.jsx" /* webpackChunkName: "component---src-pages-get-started-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-podcast-jsx": () => import("./../../../src/pages/podcast.jsx" /* webpackChunkName: "component---src-pages-podcast-jsx" */),
  "component---src-pages-services-expat-tax-services-jsx": () => import("./../../../src/pages/services/expat-tax-services.jsx" /* webpackChunkName: "component---src-pages-services-expat-tax-services-jsx" */),
  "component---src-pages-services-international-business-tax-jsx": () => import("./../../../src/pages/services/international-business-tax.jsx" /* webpackChunkName: "component---src-pages-services-international-business-tax-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-tax-agency-support-jsx": () => import("./../../../src/pages/services/tax-agency-support.jsx" /* webpackChunkName: "component---src-pages-services-tax-agency-support-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-podcast-episode-jsx": () => import("./../../../src/templates/podcast-episode.jsx" /* webpackChunkName: "component---src-templates-podcast-episode-jsx" */)
}

